import React, { Component } from "react";
import {
  Segment,
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Message,
} from "semantic-ui-react";

import { Link, Redirect } from "react-router-dom";
import {
  loginCallbackFactory,
  handleSignIn,
  sendMFAVerificationCode,
} from "../../helpers/auth";
import Verification from "../Verification";
import { saveTokens } from "../../db/Users";

const STAGE_INFO = "STAGE_INFO";
const STAGE_VERIFICATION = "STAGE_VERIFICATION";
const STAGE_REDIRECT = "STAGE_REDIRECT";

const COUNT_DOWN_RESEND = 30;

export default class Login extends Component {
  state = {
    username: "",
    password: "",
    code: "",

    stage: STAGE_INFO,
    countDown: 0,

    logging: false,
  };

  seconds = setInterval(() => {
    if (this.state.countDown > 0) {
      this.setState({
        countDown: this.state.countDown - 1,
      });
    }
  }, 1000);

  componentWillUnmount() {
    clearInterval(this.seconds);
  }

  /// //////////////////// callback for auth lib /////////////////////
  callbacks = loginCallbackFactory(
    {
      onSuccess(result) {
        let accountId = result.idToken.payload["custom:accountId"];
        let accessToken = result.getAccessToken().getJwtToken();
        let idToken = result.getIdToken().getJwtToken();
        let refreshToken = result.refreshToken.token;

        localStorage.setItem("refreshToken", refreshToken);

        saveTokens(accountId, accessToken, idToken, refreshToken, () => {
          this.setState({
            stage: STAGE_REDIRECT,
            errorMessage: "",
            logging: false,
          });
        });
      },
      onFailure(error) {
        this.setState({
          errorMessage: error,
          logging: false,
        });
      },
      mfaRequired() {
        this.setState({
          stage: STAGE_VERIFICATION,
          countDown: COUNT_DOWN_RESEND,
          errorMessage: "",
          logging: false,
        });
      },
    },
    this
  );

  /// //////////////////////// button ////////////////////////
  handleSubmit = () => {
    this.setState({
      logging: true,
    });
    handleSignIn(this.state.username, this.state.password, this.callbacks);
  };

  handleSubmitVerification = () => {
    this.setState({
      logging: true,
    });
    handleSignIn(this.state.username, this.state.password, this.callbacks);
  };

  handleResendVerification = () => {
    this.setState({
      countDown: COUNT_DOWN_RESEND,
    });
    sendMFAVerificationCode(this.state.code, this.callbacks);
  };

  /// //////////////////////// render ////////////////////////////
  renderErrorMessage = (message) => {
    // console.log('message ', message)
    return (
      <Message negative style={{ textAlign: "left" }}>
        We cannot find that account. Did you mean to{" "}
        <a href="/register">register</a>?
      </Message>
    );
  };

  renderLogin = () => {
    const { errorMessage, logging } = this.state;
    return (
      <div
        style={{
          height: "100vh",
          background: "#f9f9f9",
        }}
      >
        <Grid
          textAlign="center"
          style={{ paddingTop: 120 }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ width: 600 }} verticalAlign="middle">
            {errorMessage && this.renderErrorMessage(errorMessage)}
            <Form size="large">
              <Segment padded="very">
                <Header as="h2" textAlign="center">
                  Sign In
                </Header>
                <br />
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  onChange={(event) =>
                    this.setState({
                      username: event.target.value.trim(),
                      errorMessage: "",
                    })
                  }
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={(event) =>
                    this.setState({
                      password: event.target.value.trim(),
                      errorMessage: "",
                    })
                  }
                />
                <Button
                  color="blue"
                  fluid
                  size="large"
                  onClick={this.handleSubmit}
                  disabled={logging}
                >
                  {logging ? "Logging in..." : "Sign In"}
                </Button>
                <br />
                <p>
                  {" "}
                  New to us ? <Link to="/register"> Sign Up </Link> <br />
                  or <Link to="/forgot"> Forgot Password </Link>
                </p>
              </Segment>
            </Form>
            <Divider hidden />
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  renderVerification = () => {
    return (
      <Verification
        errorMessage={this.state.errorMessage}
        countDown={this.state.countDown}
        onChange={(event) =>
          this.setState({ code: event.target.value.trim(), errorMessage: "" })
        }
        onValidate={this.handleSubmitVerification}
        onResendCode={this.handleResendVerification}
      />
    );
  };

  renderRedirect = () => {
    // clearInterval(this.seconds)
    window.location.href = "/start";
  };

  render = () => {
    return (
      <div>
        {this.state.stage === STAGE_INFO && this.renderLogin()}
        {this.state.stage === STAGE_VERIFICATION && this.renderVerification()}
        {this.state.stage === STAGE_REDIRECT && this.renderRedirect()}
      </div>
    );
  };
}
