import React, {
    Component
} from 'react';

export default class PrivateHome extends Component {
    render() {

        return (
            <div>Private Home page</div>
        )
    }
}