import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PrivateHome from "./PrivateHome";
import PublicHome from "./PublicHome";
import Login from "./Login";
import Register from "./Register";
import Forgot from "./Forgot";
import Orders from "./Orders";
import NewOrder from "./NewOrder";
import Product from "./Product";
import FAQ from "./FAQ";
import NavHeader from "../components/Header";
import { checkUserAuthenticated } from "../helpers/auth";

const PublicRoute = ({ component: Component, isAuthed, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthed ? <Redirect to="/start" /> : <Component {...props} />
    }
  />
);

const PrivateRoute = ({ component: Component, isAuthed, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthed ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

export default class App extends Component {
  componentDidMount() {
    const isAuthed = checkUserAuthenticated();

    if (isAuthed) {
      // Add Hubspot Tracking For Chat
      const script = document.createElement("script");
      script.src = "//js.hs-scripts.com/7700904.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }

  render() {
    const isAuthed = checkUserAuthenticated();

    return (
      <div className="App">
        <NavHeader />
        <Switch>
          <PublicRoute
            isAuthed={isAuthed}
            path="/"
            exact
            component={PublicHome}
          />
          <PublicRoute
            isAuthed={isAuthed}
            path="/login"
            exact
            component={Login}
          />
          <PublicRoute
            isAuthed={isAuthed}
            path="/register"
            exact
            component={Register}
          />
          <PublicRoute
            isAuthed={isAuthed}
            path="/forgot"
            exact
            component={Forgot}
          />
          <PublicRoute
            isAuthed={isAuthed}
            path="/home"
            exact
            component={PublicHome}
          />

          <PrivateRoute
            isAuthed={isAuthed}
            path="/private"
            exact
            component={PrivateHome}
          />
          <PrivateRoute
            isAuthed={isAuthed}
            path="/start"
            exact
            component={Product}
          />
          <PrivateRoute isAuthed={isAuthed} path="/faq" exact component={FAQ} />
          <PrivateRoute
            isAuthed={isAuthed}
            path="/orders"
            exact
            component={Orders}
          />
          <PrivateRoute
            isAuthed={isAuthed}
            path="/new-order"
            exact
            component={NewOrder}
          />
          <PrivateRoute
            isAuthed={isAuthed}
            path="/order/:orderId"
            component={NewOrder}
          />

          <Redirect to="/home" />
        </Switch>
      </div>
    );
  }
}
