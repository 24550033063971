import React from "react";
import { Dropdown, Menu, Divider, Button } from "semantic-ui-react";
import {
  Table,
  Button as ButtonAnt,
  Popconfirm,
  Row,
  Col,
  Icon as IconAnt,
  Upload,
  Tag,
} from "antd";
import ReactTable from "react-table";
import { createOrder } from "../../helpers/index";
import "react-table/react-table.css";
import { EditableFormRow, EditableCell } from "../../utils/editable";

import "./OrdersTable.css";

const dummySequences = [
  {
    account_id: ["12"],
    order_id: 3,
    status: "order_submitted",
    date_created: "2020-03-12 17:02:07.052455",
    date_updated: "2020-03-12 17:02:07.052455",
  },
  {
    account_id: ["12"],
    order_id: 22,
    status: "order_submitted",
    date_created: "2020-03-13 15:29:47.022294",
    date_updated: "2020-03-13 15:29:47.022294",
  },
  {
    account_id: ["12"],
    order_id: 40,
    status: "seq_entered",
    date_created: "2020-03-24 11:13:39.704789",
    date_updated: "2020-03-27 00:33:47.011522",
  },
  {
    account_id: ["12"],
    order_id: 54,
    status: "order_submitted",
    date_created: "2020-03-24 11:29:44.888650",
    date_updated: "2020-03-24 11:29:44.888650",
  },
];

export default class OrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: this.props.account,
      page: 0,
      loading: this.props.loading,
      column: null,
      direction: null,
      url: "",
      cols: [],
      rows: this.props.orders,
      errorMessage: null,
      columns: [
        {
          title: "ID",
          dataIndex: "order_id",
          editable: false,
          render: (text) => <a href={`/order/${text}`}>{text}</a>,
        },
        {
          title: "Status",
          dataIndex: "status",
          editable: false,
          render: (status) => {
            let color = "geekblue";

            if (
              status === "order_submitted" ||
              status === "evaluation_complete"
            ) {
              color = "green";
            }
            if (status === "low") {
              color = "red";
            }
            if (status === "seq_entered" || status === "pre_evaluation") {
              color = "orange";
            }
            if (!status || status === "") {
              status = "processing";
            }

            return (
              <span>
                {
                  <Tag color={color} key={status}>
                    {status.toUpperCase()}
                  </Tag>
                }
              </span>
            );
          },
        },
        {
          title: "Date Created",
          dataIndex: "date_created",
          editable: false,
        },
        {
          title: "Date Updated",
          dataIndex: "date_updated",
          editable: false,
        },
      ],
    };

    // this.renderTable = this.renderTable.bind(this);
  }

  componentDidMount = () => {
    // console.log('orders ', this.state.rows)
  };

  renderTable = (rows) => {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.state.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <div key="orders-table" className="ordersTable--main">
        <div
          className="resultsTable-header"
          style={{ justifyContent: "flex-end" }}
        >
          <Menu
            compact
            style={{ border: "none", boxShadow: "none", paddingBottom: "20px" }}
          >
            <Button
              color="blue"
              style={{ margin: "0" }}
              size="medium"
              onClick={async () => {
                let order = await createOrder(this.props.idToken);
                console.log("order ", order);
                window.location.href = "/order/" + order.order_id;
              }}
            >
              Place New Order
            </Button>
          </Menu>
        </div>
        {/* <Divider /> */}
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={rows}
          columns={columns}
        />
      </div>
    );
  };

  render() {
    let { rows } = this.state;

    return this.renderTable(rows);
  }
}
