import React, { useEffect, useState } from "react";
import Prismic from "prismic-javascript";
import { Client } from "../helpers/prismic";

import styled from "styled-components";

import { Popup } from "semantic-ui-react";

const TooltipLink = styled.span`
  cursor: pointer;
  color: #000;
  border-bottom: 1px dashed #000;
`;

const Hint = ({ content, id }) => {
  const [hintData, setHintData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.id", id)
      );
      if (response && response.results.length > 0) {
        setHintData(response.results[0].data);
      }
    };
    fetchData();
  }, [id]);

  return (
    hintData && (
      <Popup
        hoverable
        position="top center"
        trigger={<TooltipLink>{content}</TooltipLink>}
        key={id}
      >
        <p>
          <img
            src={hintData.image.url}
            width={hintData.image.dimensions.width}
            height={hintData.image.dimensions.height}
            alt="Hint"
          />
        </p>
        <p>{hintData.content}</p>
      </Popup>
    )
  );
};

export default Hint;
