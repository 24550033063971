import React from 'react'
import { Dropdown, Menu, Divider, Button, Step, Icon } from 'semantic-ui-react'

import _ from 'lodash'
import { Table, Button as ButtonAnt, Popconfirm, Row, Col, Icon as IconAnt, Upload, Tag, Result } from "antd"
import { ExcelRenderer } from "react-excel-renderer"
import { removeSequences } from '../../helpers/index';
import { EditableFormRow, EditableCell } from "../../utils/editable";

// import "react-table/react-table.css";

import './OrderStepThree.css';

const dummySequences = [
  {
    rowKey: '1',
    name: 'B12',
    origin: 'synthetic',
    sequence: 'BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH',
    feasability: '',
    flags: ''
  },
  {
    rowKey: '2',
    name: 'B12',
    origin: 'synthetic',
    sequence: 'BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH',
    feasability: 'high',
    flags: ''
  },
  {
    rowKey: '3',
    name: 'B22',
    origin: 'eukaryota',
    sequence: 'BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH',
    feasability: 'medium',
    flags: 'Contains putative signal sequence'
  },
  {
    rowKey: '4',
    name: 'B24',
    origin: 'prokaryota',
    sequence: 'BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH',
    feasability: 'low',
    flags: 'Contains putative signal sequence'
  },
]

export default class OrderStepThree extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false,
      account: this.props.account,
      processedSequences: this.props.processedSequences || [],
      cols: [],
      rows: this.props.processedSequences || [],
      errorMessage: null,
      canSubmit: false,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          editable: false
        },
        {
          title: "Origin",
          dataIndex: "origin",
          editable: false
        },
        {
          title: "Sequence",
          dataIndex: "sequence",
          editable: false
        },
        {
          title: "Feasability",
          dataIndex: "feasability",
          editable: false,
          render: (feasability) => {
            let color = 'geekblue';

            if (feasability === 'Standard') {
              color = 'green';
            }
            if (feasability === 'Reject') {
              color = 'red';
            }
            if (feasability == 'medium') {
              color = 'orange';
            }
            if (!feasability || feasability === '') {
              feasability = 'processing'
            }
            
            return (
              <span>
                { 
                  <Tag color={color} key={feasability}>
                    {feasability.toUpperCase()}
                  </Tag>
                }
              </span>
            )
          }
        },
        {
          title: "Flags",
          dataIndex: "flags",
          editable: false,
          render: (flag) => {
            let _flag;

            if (!flag) {
              _flag = 'N/A'
            }

            return (
              <span>{flag}</span>
            )
          }
        },
        // {
        //   title: "Action",
        //   dataIndex: "action",
        //   render: (text, record) =>
        //     this.state.rows.length >= 1 ? (
        //       <Popconfirm
        //         title="Sure to delete?"
        //         onConfirm={() => this.handleDelete(record.id)}
        //       >
        //         <IconAnt
        //           type="delete"
        //           theme="filled"
        //           style={{ color: "red", fontSize: "20px" }}
        //         />
        //       </Popconfirm>
        //     ) : null
        // }
      ]
    }

    this.page = 0;
  }

  componentDidMount = () => {
    let {processedSequences} = this.state;
    let sequencesAssessed = processedSequences.every( (val, i, arr) => val.feasability === 'Standard' )
    // console.log(processedSequences)

    if (processedSequences.length > 0 && sequencesAssessed) {
      this.setState({canSubmit: true})
    }
  }

  handleSave = row => {
    const newData = [...this.state.rows];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ rows: newData });
  };

  checkFile(file) {
    let errorMessage = "";
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === "application/vnd.ms-excel" ||
      file[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      errorMessage = "You can only upload Excel file!";
    }
    console.log("file", file[0].type);
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorMessage = "File must be smaller than 2MB!";
    }
    console.log("errorMessage", errorMessage);
    return errorMessage;
  }

  fileHandler = fileList => {
    console.log("fileList", fileList);
    let fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: "No file uploaded!"
      });
      return false;
    }
    console.log("fileObj.type:", fileObj.type);
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      this.setState({
        errorMessage: "Unknown file format. Only Excel files are uploaded!"
      });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let newRows = [];
        resp.rows.slice(1).map((row, index) => {
          if (row && row !== "undefined") {
            newRows.push({
              key: index,
              name: row[0],
              origin: row[1],
              sequence: row[2]
            });
          }
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: "No data found in file!"
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {
    console.log("submitting: ", this.state.rows);
    this.setState({ isSubmitted: true });

    this.props.saveAndContinue(3, [], null, true)
    // window.location.href = '/orders'
    //submit to API
    //if successful, banigate and clear the data
    //this.setState({ rows: [] })
  };

  handleDelete = async (id) => {
    await removeSequences(this.props.idToken, this.props.order_id, [id])
    const rows = [...this.state.rows];
    this.setState({ rows: rows.filter(item => item.id !== id) });
  };
  handleAdd = () => {
    const { count, rows } = this.state;
    const newData = {
      key: count,
      name: "B21",
      origin: "Synthetic",
      sequence: "BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH"
    };
    this.setState({
      rows: [newData, ...rows],
      count: count + 1
    });
  };

  renderSuccessConfirmation = () => {
    return (
      <Result
        status="success"
        title="Your order has been submitted!"
        subTitle="Watch this space for updates and credit card payment in the near future. Meantime, our team will reach out to you with payment info or any next steps."
        extra={[
          <Button type="primary" key="console" onClick={() => window.location.href = '/orders'}>
            See All Orders
          </Button>,
          <Button key="buy" onClick={() => window.location.href = '/new-order'}>Create New</Button>,
        ]}
      />
    )
  }

  StageHeader = () => (
    <Step.Group fluid stackable='tablet'>
      <Step link onClick={() => this.props.toggleStep(1)}>
        <Icon name='dna' />
        <Step.Content>
          <Step.Title>Sequences</Step.Title>
          <Step.Description>Add your sequences</Step.Description>
        </Step.Content>
      </Step>
      <Step disabled={this.props.isDisabledTwo} link onClick={() => this.props.toggleStep(2)}>
        <Icon name='tasks' />
        <Step.Content>
          <Step.Title>Additional Info</Step.Title>
          <Step.Description>Enter any special requirements</Step.Description>
        </Step.Content>
      </Step>
      <Step active disabled={this.props.isDisabledThree} link onClick={() => this.props.toggleStep(3)}>
        <Icon name='cart' />
        <Step.Content>
          <Step.Title>Review Order</Step.Title>
          <Step.Description>Verify order details</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  )

  renderStepOne = () => {
    let { loading, total, url, results, isSubmitted, canSubmit } = this.state;
    // console.log(loading, total, url, results )

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columns = this.state.columns.map(col => {
      // if (!col.editable) {
      //   return col;
      // }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          key: col.key,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });

    return (
      <div key={url} className='ordersTable--main' style={{ paddingTop: '40px', width: '90%', margin: '0 auto'}}>
        {isSubmitted ? 
          this.renderSuccessConfirmation()
          :
          <React.Fragment>
            <div className='menu-container' style={{ }}>
              {this.StageHeader()}
            </div>
            <h2 style={{padding: '20px'}}>Your user account will be emailed when analysis is completed.</h2>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              dataSource={this.state.rows}
              columns={columns}
            />
            <div className='menu-container' style={{ justifyContent: 'flex-end'}}>
              <Menu compact style={{ border: 'none', boxShadow: 'none', marginTop: '20px'}}>
                <Button basic size='medium'
                  onClick={() => window.location.href = '/orders'}
                >
                  Go to my orders list
                </Button>
                <Button 
                  color='blue' 
                  size='medium'
                  onClick={() => this.handleSubmit()}
                  disabled={!canSubmit}
                >
                  Submit Order  <Icon name="arrow right" />
                </Button>
              </Menu>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }

  render() {
    let { url } = this.state;

    return (
        this.renderStepOne()
    )
  }
}