import React, { useEffect, useState } from "react";
import { Segment, Grid, Loader, Header, Container } from "semantic-ui-react";

import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import { Client } from "../../helpers/prismic";

const FAQPage = () => {
  const [faqs, setFaqs] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "faq")
      );
      if (response) {
        setFaqs(response.results);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {faqs && !loading ? (
        <Container>
          <Segment basic padded="very">
            <Header as="h1" textAlign="center">
              Frequently Asked Questions
            </Header>
          </Segment>
          <Grid stackable relaxed padded stretched>
            {faqs.map((faq, idx) => (
              <Grid.Column computer={8} key={idx}>
                <Segment basic padded>
                  <Header as="h4">{faq.data.question[0].text}</Header>
                  <RichText render={faq.data.answer} />
                </Segment>
              </Grid.Column>
            ))}
          </Grid>
        </Container>
      ) : (
        <Loader active />
      )}
    </>
  );
};

export default FAQPage;
