import CONFIG from '../config'
import axios from 'axios';
import _ from "lodash";

import AWS from 'aws-sdk'

AWS.config.region = CONFIG.Region;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: CONFIG.CognitoIdentityPool,
  RoleArn: CONFIG.RoleArn,
  AccountId: CONFIG.AccountId, // your AWS account ID
});

const lambda = new AWS.Lambda({
  region:CONFIG.Region || 'us-east-1'
});

export function requestData(pageSize, page, sorted, filtered, url) {

  let _url = `${url}&offset=${(page - 1) * 30}`;

  if ((page === 0) || (page === 1)) {
    _url = url;
  }

  return fetch(_url)
    .then(res => res.json())
    .then((results) => {
      let filteredData = results.bundle;

      if (filtered.length) {
        filteredData = filtered.reduce((filteredSoFar, nextFilter) => {
          return filteredSoFar.filter(row => {
            return (row[nextFilter.id] + "").includes(nextFilter.value);
          });
        }, filteredData);
      }

      const sortedData = _.orderBy(
        filteredData,
        sorted.map(sort => {
          return row => {
            if (row[sort.id] === null || row[sort.id] === undefined) {
              return -Infinity;
            }
            return typeof row[sort.id] === "string" ?
              row[sort.id].toLowerCase() :
              row[sort.id];
          };
        }),
        sorted.map(d => (d.desc ? "desc" : "asc"))
      );

      const res = {
        rows: sortedData,
        pages: Math.ceil(results.total / pageSize),
        page: page
      };

      return res;
    });
};

export function updateOrderInfo(accessToken, orderId, status) {
  const headers = {
    // 'Content-Type': 'application/json',
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }

  return axios.patch(`${CONFIG.ORDER_SERVICE}/${orderId}?status=${status}`, {}, {
    headers: headers
  })
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    return error
  })
}

export function getOrderInfo(accessToken, orderId) {
  const headers = {
    // 'Content-Type': 'application/json',
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }

  return axios.get(`${CONFIG.ORDER_SERVICE}/${orderId}`, {
    headers: headers
  })
  .then((response) => {
    return response.data[0]
  })
  .catch((error) => {
    return error
  })
}

export function getOrders(accessToken) {
  const headers = {
    // 'Content-Type': 'application/json',
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }

  return axios.get(`${CONFIG.ORDER_SERVICE}/`, {
    headers: headers
  })
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    return error
  })
}

export function createOrder(accessToken) {
  const headers = {
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }
  
  return axios.post(`${CONFIG.ORDER_SERVICE}/`, {}, {
    headers: headers
  })
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    return error
  })
}

export function loadSequences(accessToken, orderId) {
  const headers = {
    // 'Content-Type': 'application/json',
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }

  return axios.get(`${CONFIG.ORDER_SERVICE}/${orderId}/sequence`, {
    headers: headers
  })
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    return error
  })
}

export function submitSequences(accessToken, orderId, sequences, status) {
  const headers = {
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }
  
  return axios.post(`${CONFIG.ORDER_SERVICE}/${orderId}/sequence?status=${status}`, sequences, {
    headers: headers
  })
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    return error
  })
}

export function loadSequenceMetadata(accessToken, orderId) {
  const headers = {
    // 'Content-Type': 'application/json',
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }

  return axios.get(`${CONFIG.ORDER_SERVICE}/${orderId}/sequence/metadata`, {
    headers: headers
  })
  .then((response) => {
    return response.data.orderMetadata
  })
  .catch((error) => {
    return error
  })
}

export function submitMetadata(accessToken, orderId, metadata) {
  const headers = {
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }
  
  return axios.post(`${CONFIG.ORDER_SERVICE}/${orderId}/sequence/metadata`, { 'orderMetadata': metadata }, {
    headers: headers
  })
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    return error
  })
}

export function loadProcessedSequences(accessToken, orderId) {
  const headers = {
    // 'Content-Type': 'application/json',
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }

  return axios.get(`${CONFIG.ORDER_SERVICE}/${orderId}/sequence/process`, {
    headers: headers
  })
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    return error
  })
}

export function removeSequences(accessToken, orderId, sequences) {
  const headers = {
    'x-api-key': CONFIG.API_KEY,
    'Authorization': accessToken,
  }
  
  return axios.delete(`${CONFIG.ORDER_SERVICE}/${orderId}/sequence`, {
    headers: headers,
    data: {
      sequencesToRemove: sequences
    }
  })
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    return error
  })
}