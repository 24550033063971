import React, { Component } from "react";
import { Grid, Loader, Segment, Dimmer } from "semantic-ui-react";
import OrdersTable from "../../components/OrdersTable";
import { getCurrentUser, refreshSession } from "../../helpers/auth";
import { getOrders, createOrder } from "../../helpers";
import { getUserInfo } from "../../db/Users";

const dummyOrders = [
  {
    id: 1,
    status: "saved",
    createdBy: "zsanders",
    createdAt: "3/23/2020",
  },
  {
    id: 2,
    status: "submitted",
    createdBy: "zsanders",
    createdAt: "3/24/2020",
  },
  {
    id: 3,
    status: "processing",
    createdBy: "zsanders",
    createdAt: "3/24/2020",
  },
];

export default class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {},
      orders: [],
      loading: true,
      modal: false,
      idToken: "",
    };
  }

  componentDidMount = () => {
    getCurrentUser((err, account) => {
      if (!err) {
        return getUserInfo(account["custom:accountId"])
          .then((account) => {
            return refreshSession(
              account.refreshToken,
              async (err, session) => {
                if (!err) {
                  let idToken = session.getIdToken().getJwtToken();
                  let _orders = await getOrders(idToken);

                  let orders = _orders.length > 0 ? _orders : [];
                  this.setState({ orders, account, idToken, loading: false });
                } else {
                  console.log("error ", err);
                }
              }
            );
          })
          .catch((err) => console.log("err ", err));
      }

      console.log("err ", err);
      return;
    });
  };

  renderOrdersTable = (account) => {
    let { loading, orders, idToken } = this.state;

    return (
      <div>
        <Segment basic>
          {!loading ? (
            <OrdersTable
              account={account}
              loading={loading}
              orders={orders}
              idToken={idToken}
            />
          ) : (
            <Segment basic padded>
              <Loader active />
            </Segment>
          )}
        </Segment>
      </div>
    );
  };

  render() {
    let { account, orders } = this.state;

    return (
      <div>
        {/* {Object.keys(account).length !== 0 ? this.renderOrdersTable(account, orders) : ''} */}
        {this.renderOrdersTable(account)}
      </div>
    );
  }
}
