import React from "react";
import Prismic from "prismic-javascript";
import CONFIG from "../config";

import Hint from "../components/Hint";

const apiEndpoint = CONFIG.PRISMIC_ENDPOINT;
const accessToken = CONFIG.PRISMIC_TOKEN;

export const Client = Prismic.client(apiEndpoint, { accessToken });

export const customLink = (type, element, content, children, index) => {
  if (element.data.type === "popover") {
    return <Hint content={content} id={element.data.id} />;
  } else {
    return (
      <a key={element.data.id} href={element.data.url}>
        {content}
      </a>
    );
  }
};
