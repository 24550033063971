//PROD
const CONFIG = {
  aws_cognito_region: "us-west-2",
  aws_cognito_user_pools_id: "us-west-2_AXoF68Q9X",
  aws_cognito_user_pools_web_client_id: "442vofvfj6bot44jhonh0s77dg",
  // RoleArn: 'arn:aws:cognito-idp:us-west-2:310444341879:userpool/us-west-2_AXoF68Q9X',
  RoleArn: "arn:aws:iam::310444341879:role/Cognito_tierra_identity_poolUnauth_Role",
  AccountId: "310444341879",
  DDBUserTable: "tierra-users-prod",
  CognitoIdentityPool: "us-west-2:32635785-b0c0-489c-adaf-b821ed260203",
  Region: "us-west-2",
  supportEmail: "support@tierra.com",
  API_KEY: "uD1I8UvXY55Wd3aamTZ071UCcWW7mvXj37pHDG35",
  // ORDER_SERVICE: "https://4fc1b9t9a0.execute-api.us-west-1.amazonaws.com/dev/v1/order", // Local
  // ORDER_SERVICE: "https://4fc1b9t9a0.execute-api.us-west-1.amazonaws.com/test/v1/order", // Test
  ORDER_SERVICE: "https://4fc1b9t9a0.execute-api.us-west-1.amazonaws.com/prod/v1/order", // Production
  PRISMIC_ENDPOINT: "https://tierrawebsitemain.prismic.io/api/v2",
  PRISMIC_TOKEN: "MC5YcXgtUWhFQUFDSUFqaVlX.PRQ_77-9Ohnvv73vv73vv70YBx_vv73vv70S77-9aTzvv70677-9V15TPCRrGu-_vXXvv73vv70",
};

export default CONFIG;
