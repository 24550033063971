import React from "react";
import styled from "styled-components";
import { Button, Container } from "semantic-ui-react";

import LogoSVG from "../../assets/images/sol-logo.svg";
import DotsHexagonSVG from "../../assets/images/dots-hexagon.svg";

const LogoImg = styled.img`
  display: block;
  margin: 2rem auto;
`;

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  background: url(${DotsHexagonSVG}) 50% 50% no-repeat;
`;

const HomepageLayout = () => (
  <PageWrapper>
    <Container text textAlign="center">
      <LogoImg src={LogoSVG} />
      <Button
        primary
        size="large"
        onClick={() => (window.location.href = "/register")}
      >
        Register
      </Button>
      <Button size="large" onClick={() => (window.location.href = "/login")}>
        Sign In
      </Button>
    </Container>
  </PageWrapper>
);

export default HomepageLayout;
