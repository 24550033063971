import React, { Component } from "react";
import { Grid, Modal, Button } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import OrderStepOne from "../../components/OrderStepOne";
import OrderStepTwo from "../../components/OrderStepTwo";
import OrderStepThree from "../../components/OrderStepThree";
import { getCurrentUser, refreshSession } from "../../helpers/auth";
import {
  getOrderInfo,
  loadSequences,
  submitSequences,
  loadSequenceMetadata,
  submitMetadata,
  loadProcessedSequences,
  updateOrderInfo,
} from "../../helpers/index";
import { formatSequenceFromAPI } from "../../utils/sequence";
import { getUserInfo } from "../../db/Users";

export default class NewOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order_id: this.props.match.params.orderId,
      order: {},
      account: {},
      loading: true,
      modal: false,
      isStepOneClean: false,
      isStepTwoClean: true,
      isStepThreeClean: true,
      currentOrderStep: 1,
      sequences: [],
      metadata: "",
      processedSequences: [],
      idToken: "",
    };
  }

  componentDidMount() {
    console.log(process.env)
    return getCurrentUser((err, account) => {
      if (!err) {
        return getUserInfo(account["custom:accountId"])
          .then((account) => {
            return refreshSession(
              account.refreshToken,
              async (err, session) => {
                if (!err) {
                  let order,
                    sequences,
                    metadata,
                    processedSequences,
                    isStepTwoClean,
                    isStepThreeClean = true,
                    isOrderSubmitted;
                  let idToken = session.getIdToken().getJwtToken();
                  localStorage.setItem("idToken", idToken);
                  if (this.state.order_id) {
                    order = await getOrderInfo(idToken, this.state.order_id);
                    // console.log(order)

                    sequences = await loadSequences(
                      idToken,
                      this.state.order_id
                    );
                    metadata = await loadSequenceMetadata(
                      idToken,
                      this.state.order_id
                    );
                    processedSequences = await loadProcessedSequences(
                      idToken,
                      this.state.order_id
                    );

                    // if (sequences.length > 0) {
                    //   await updateOrderInfo(idToken, this.state.order_id, 'seq_entered')
                    // }

                    if (metadata.length > 0) {
                      isStepTwoClean = false;
                      isStepThreeClean = false;
                      // await updateOrderInfo(idToken, this.state.order_id, 'pre_evaluation')
                    } else {
                      isStepTwoClean = true;
                    }

                    // if (processedSequences.length > 0) {
                    //   isStepThreeClean = false
                    // } else {
                    //   isStepThreeClean = true
                    // }

                    // console.log('get order info ', order)

                    if (order.status == "order_submitted" || order.status == "evaluation_complete") {
                      isOrderSubmitted = true;
                      this.setVisitedStage(3);
                    } else if (order.status == "pre_evaluation") {
                      this.setVisitedStage(2);
                    } else {
                      this.setVisitedStage(1);
                    }
                  }
                  this.setState({
                    account,
                    order,
                    sequences,
                    metadata: metadata,
                    idToken,
                    processedSequences,
                    loading: false,
                    isStepTwoClean,
                    isStepThreeClean,
                    isOrderSubmitted
                  });
                } else {
                  console.log("error ", err);
                }
              }
            );
          })
          .catch((err) => console.log("err ", err));
      }

      console.log("err ", err);
      return;
    });
  }

  setVisitedStage = (stage) => {
    switch (stage) {
      case 1:
        this.setState({ isStepOneClean: false, currentOrderStep: stage });
        break;

      case 2:
        this.setState({ isStepTwoClean: false, currentOrderStep: stage });
        break;

      case 3:
        this.setState({ isStepThreeClean: false, currentOrderStep: stage });
        break;

      default:
        break;
    }
  };

  triggerToggleStep = (nextStepNumber) => {
    this.setVisitedStage(nextStepNumber);
    // this.setState({ currentOrderStep: nextStepNumber});
  };

  triggerSaveAndContinue = async (
    nextStepNumber,
    sequences = [],
    metadata = {},
    submitted = false
  ) => {
    let { idToken, order_id, order } = this.state;

    // API Call to save sequence data 
    // console.log('saving data to API', sequences, order.status);

    if (sequences.length > 0) {
      let results = await submitSequences(
        idToken,
        order_id,
        { sequences },
        order.status
      );
      await updateOrderInfo(idToken, order_id, "seq_entered");
      // console.log('submitSequences ', results)
    }

    if (metadata) {
      let results = await submitMetadata(idToken, order_id, metadata);
      await updateOrderInfo(idToken, order_id, "pre_evaluation");
      // console.log('submit metadata ', results)
    }

    if (submitted) {
      await updateOrderInfo(idToken, order_id, "order_submitted");
    }

    this.setVisitedStage(nextStepNumber);

    // Proceed to next step
    this.setState({
      currentOrderStep: nextStepNumber,
      sequences,
      metadata,
    });
  };

  renderOrderStepOne = (sequences) => {
    let {
      currentOrderStep,
      isStepOneClean,
      isStepTwoClean,
      isStepThreeClean,
      metadata,
      processedSequences,
      idToken,
      order_id,
      order,
      isOrderSubmitted,
    } = this.state;
    let _sequences, _processedSequences;

    if (typeof sequences == "string") {
      sequences = JSON.parse(sequences);
    }
    _sequences = formatSequenceFromAPI(sequences);
    _processedSequences = formatSequenceFromAPI(processedSequences);

    // console.log('_sequences ', _sequences);

    // console.log('isStepOneClean, isStepTwoClean, isStepThreeClean', isStepOneClean, isStepTwoClean, isStepThreeClean)

    return (
      <React.Fragment>
        {currentOrderStep === 1 ? (
          <OrderStepOne
            order_id={order_id}
            order={order}
            isOrderSubmitted={isOrderSubmitted}
            idToken={idToken}
            sequences={_sequences}
            isDisabledOne={this.state.isStepOneClean}
            isDisabledTwo={this.state.isStepTwoClean}
            isDisabledThree={this.state.isStepThreeClean}
            toggleStep={this.triggerToggleStep}
            saveAndContinue={this.triggerSaveAndContinue}
          />
        ) : null}
        {currentOrderStep === 2 ? (
          <OrderStepTwo
            sequences={_sequences}
            metadata={metadata}
            isDisabledOne={this.state.isStepOneClean}
            isDisabledTwo={this.state.isStepTwoClean}
            isDisabledThree={this.state.isStepThreeClean}
            toggleStep={this.triggerToggleStep}
            saveAndContinue={this.triggerSaveAndContinue}
          />
        ) : null}
        {currentOrderStep === 3 ? (
          <OrderStepThree
            order_id={order_id}
            idToken={idToken}
            sequences={_sequences}
            processedSequences={_processedSequences}
            isDisabledOne={this.state.isStepOneClean}
            isDisabledTwo={this.state.isStepTwoClean}
            isDisabledThree={this.state.isStepThreeClean}
            toggleStep={this.triggerToggleStep}
            saveAndContinue={this.triggerSaveAndContinue}
          />
        ) : null}
      </React.Fragment>
    );
  };

  render() {
    let { account, sequences, loading, currentOrderStep } = this.state;

    return (
      <div>
        <Grid
          style={{ marginLeft: "0px", marginRight: "none" }}
          stackable
          verticalAlign="top"
        >
          <Grid.Row style={{ paddingTop: "0px" }}>
            <Grid.Column style={{ width: "100%" }} stretched>
              {!loading ? (
                this.renderOrderStepOne(sequences)
              ) : (
                <div style={{ width: "90%", padding: "60px 80px" }}>
                  <Skeleton avatar paragraph={{ rows: 1 }} paragraph={true} />
                  <Skeleton avatar paragraph={{ rows: 1 }} paragraph={true} />
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
