import React, { Component } from "react";
import { handleSignOut, checkUserAuthenticated } from "../../helpers/auth";
import { Button, Menu, Segment, Label } from "semantic-ui-react";
import { Link, NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";

import LogoSVG from "../../assets/images/sol-logo.svg";

const FixedHeader = styled.div`
  position: fixed;
  top: 0;
  padding: 10px;
  width: 100%;
  text-align: center;
`;

class Header extends Component {
  handleSignOutClick = async () => {
    await handleSignOut();
    window.location.href = "/orders";
  };

  handleSignInClick = () => {
    this.props.history.push("/login");
  };

  renderPublicHeader = () => {
    return (
      <FixedHeader>
        <Link to="/">
          <img src={LogoSVG} alt="Logo" />
        </Link>
      </FixedHeader>
    );
  };

  renderPrivateHeader = () => {
    return (
      <div>
        <Menu pointing secondary stackable>
          <Menu.Item>
            <Link to="/">
              <img src={LogoSVG} alt="Logo" />
            </Link>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item
              as={NavLink}
              to="/start"
              activeClassName="active"
              style={{ height: "100%" }}
            >
              Start a New Order
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/orders"
              activeClassName="active"
              style={{ height: "100%" }}
            >
              My Orders
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/faq"
              activeClassName="active"
              style={{ height: "100%" }}
            >
              FAQ
            </Menu.Item>
            <Menu.Item
              onClick={this.handleSignOutClick}
              position="right"
              style={{ height: "100%" }}
            >
              Sign Out
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  };

  render() {
    const userIsLoggedIn = checkUserAuthenticated();
    return this.props.location.pathname !== "/" ? (
      <div>
        {!userIsLoggedIn && this.renderPublicHeader()}
        {userIsLoggedIn && this.renderPrivateHeader()}
      </div>
    ) : null;
  }
}

export default withRouter(Header);
