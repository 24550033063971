import React from 'react'
import { Menu, Divider, Button, Radio, Form, Step, Icon, Header, TextArea } from 'semantic-ui-react'
import _ from 'lodash'

import "react-table/react-table.css";

import './OrderStepTwo.css';

export default class OrderStepOne extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: this.props.account,
      errorMessage: '',
      metadata: this.props.metadata,
      'molecule-cofactor': 'no',
      'molecule-cofactor-information': '',
      'divalent-cations': 'no',
      'divalent-cations-information': '',
      'ptm-required': 'no',
      'ptm-required-information': '',
      'chaperone': 'no',
      'chaperone-information': '',
      'terminal-scar': 'no',
      'terminal-scar-information': '',
      'protein-requirements': 'no',
      'protein-requirements-information': '',
    }

    this.page = 0;
  }

  componentDidMount = () => {
    // console.log('metadata ', this.state.metadata)
    if (this.state.metadata && this.state.metadata.length > 0) {
      this.hydrateQuestions()
    }
  }

  hydrateQuestions = () => {
    let { metadata} = this.state;

    metadata.forEach(element => {
      let questionId = element.questionId;
      let answer = element.answer;
      
      console.log(questionId, answer)
      this.setState({ [questionId]: answer})

      if (answer == 'yes' || answer == 'unsure') {
        let questionIdAdditionInfoKey = `${questionId}-information`;
        let questionIdAdditionInfoValue = element.information;
        console.log(questionIdAdditionInfoKey, questionIdAdditionInfoValue)
        this.setState({ [questionIdAdditionInfoKey]: questionIdAdditionInfoValue.toString()})
      }
    });
  }

  StageHeader = () => (
    <Step.Group fluid stackable='tablet'>
      <Step link onClick={() => this.props.toggleStep(1)}>
        <Icon name='dna' />
        <Step.Content>
          <Step.Title>Sequences</Step.Title>
          <Step.Description>Add your sequences</Step.Description>
        </Step.Content>
      </Step>
      <Step active>
        <Icon name='tasks' />
        <Step.Content>
          <Step.Title>Additional Info</Step.Title>
          <Step.Description>Enter any special requirements</Step.Description>
        </Step.Content>
      </Step>
      <Step disabled={this.props.isDisabledThree} link onClick={() => this.props.toggleStep(3)}>
        <Icon name='cart' />
        <Step.Content>
          <Step.Title>Review Order</Step.Title>
          <Step.Description>Verify order details</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  )

  renderQuestionaire = () => {
    return (
      <Form>
        <Header as='h2' color='black' style={{ paddingTop: '40px', }}>
          What you need us to know?
        </Header>
        <Header as='h3' color='black'>
          Do your genes have any of the following special requirements...
        </Header>
        <br/>
        <Form.Group grouped required style={{padding: '10px'}}>
          <label>Small molecule cofactor for folding/activity</label>
          <br/>
          <Radio
            label='No'
            name='molecule-cofactor'
            value='no'
            checked={this.state['molecule-cofactor'] === 'no'}
            onChange={this.handleChange}
          />
           <br/>
          <Radio
            label='Yes'
            name='molecule-cofactor'
            value='yes'
            checked={this.state['molecule-cofactor'] === 'yes'}
            onChange={this.handleChange}
          />
           <br/>
          <Radio
            label='Unsure'
            name='molecule-cofactor'
            value='unsure'
            checked={this.state['molecule-cofactor'] === 'unsure'}
            onChange={this.handleChange}
          />
          <br/>
          {this.state['molecule-cofactor'] && this.state['molecule-cofactor'] !== 'no' 
          ? <TextArea 
              onChange={this.handleChange}
              value={this.state['molecule-cofactor-information']}
              name='molecule-cofactor-information' 
              placeholder='Please tell us more' />
          : ''}
        </Form.Group>
        <Form.Group grouped required style={{padding: '10px'}}>
          <label>Divalent cations for folding/stability?</label>
          <br/>
          <Radio
            label='No'
            name='divalent-cations'
            value='no'
            checked={this.state['divalent-cations'] === 'no'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Yes'
            name='divalent-cations'
            value='yes'
            checked={this.state['divalent-cations'] === 'yes'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Unsure'
            name='divalent-cations'
            value='unsure'
            checked={this.state['divalent-cations'] === 'unsure'}
            onChange={this.handleChange}
          />
          <br/>
          {this.state['divalent-cations'] && this.state['divalent-cations'] !== 'no' 
          ? <TextArea 
              onChange={this.handleChange}
              value={this.state['divalent-cations-information']}
              name='divalent-cations-information' 
              placeholder='Please tell us more' />
          : ''}
        </Form.Group>
        <Form.Group grouped required style={{padding: '10px'}}>
          <label>PTM required during synthesis for folding/activity?</label>
          <br/>
          <Radio
            label='No'
            name='ptm-required'
            value='no'
            checked={this.state['ptm-required'] === 'no'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Yes'
            name='ptm-required'
            value='yes'
            checked={this.state['ptm-required'] === 'yes'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Unsure'
            name='ptm-required'
            value='unsure'
            checked={this.state['ptm-required'] === 'unsure'}
            onChange={this.handleChange}
          />
          <br/>
          {this.state['ptm-required'] && this.state['ptm-required'] !== 'no' 
          ? <TextArea 
              onChange={this.handleChange}
              value={this.state['ptm-required-information']}
              name='ptm-required-information' 
              placeholder='Please tell us more' />
          : ''}
        </Form.Group>
        <Form.Group grouped required style={{padding: '10px'}}>
          <label>Absolutely require a specific chaperone?</label>
          <br/>
          <Radio
            label='No'
            name='chaperone'
            value='no'
            checked={this.state['chaperone'] === 'no'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Yes'
            name='chaperone'
            value='yes'
            checked={this.state['chaperone'] === 'yes'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Unsure'
            name='chaperone'
            value='unsure'
            checked={this.state['chaperone'] === 'unsure'}
            onChange={this.handleChange}
          />
          <br/>
          {this.state['chaperone'] && this.state['chaperone'] !== 'no' 
          ? <TextArea 
              onChange={this.handleChange}
              value={this.state['chaperone-information']}
              name='chaperone-information' 
              placeholder='Please tell us more' />
          : ''}
        </Form.Group>
        <Form.Group grouped required style={{padding: '10px'}}>
          <label>Are your proteins inactivated by a non-native terminal scar?</label>
          <br/>
          <Radio
            label='No'
            name='terminal-scar'
            value='no'
            checked={this.state['terminal-scar'] === 'no'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Yes'
            name='terminal-scar'
            value='unsure'
            checked={this.state['terminal-scar'] === 'unsure'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Unsure'
            name='terminal-scar'
            value='yes'
            checked={this.state['terminal-scar'] === 'yes'}
            onChange={this.handleChange}
          />
          <br/>
          {this.state['terminal-scar'] && this.state['terminal-scar'] !== 'no' 
          ? <TextArea 
              onChange={this.handleChange}
              value={this.state['terminal-scar-information']}
              name='terminal-scar-information' 
              placeholder='Please tell us more' />
          : ''}
        </Form.Group>
        <Form.Group grouped required style={{padding: '10px'}}>
          <label>Anything else we need to know?</label>
          <br/>
          <Radio
            label='No'
            name='protein-requirements'
            value='no'
            checked={this.state['protein-requirements'] === 'no'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Yes'
            name='protein-requirements'
            value='yes'
            checked={this.state['protein-requirements'] === 'yes'}
            onChange={this.handleChange}
          />
          <br/>
          <Radio
            label='Unsure'
            name='protein-requirements'
            value='unsure'
            checked={this.state['protein-requirements'] === 'unsure'}
            onChange={this.handleChange}
          />
          <br/>
          {this.state['protein-requirements'] && this.state['protein-requirements'] !== 'no' 
          ? <TextArea 
              onChange={this.handleChange}
              value={this.state['protein-requirements-information']}
              name='protein-requirements-information' 
              placeholder='Please tell us more' />
          : ''}
        </Form.Group>
        
      </Form>
    )
  }

  handleChange = (e, { name,value }) => { 
    console.log({[name]: value})
    this.setState({ [name]: value })
  }


  handleSubmit = () => {
    console.log(this.state)

    if ( 
      (this.state['molecule-cofactor'] == 'yes' && !this.state['molecule-cofactor-information']) || 
      (this.state['divalent-cations'] == 'yes' && !this.state['divalent-cations-information']) ||
      (this.state['ptm-required'] == 'yes' && !this.state['ptm-required-information']) ||
      (this.state['chaperone'] == 'yes' && !this.state['chaperone-information']) ||
      (this.state['terminal-scar'] == 'yes' && !this.state['terminal-scar-information']) ||
      (this.state['protein-requirements'] == 'yes' && !this.state['protein-requirements-information']) ||
      (this.state['molecule-cofactor'] == 'unsure' && !this.state['molecule-cofactor-information']) || 
      (this.state['divalent-cations'] == 'unsure' && !this.state['divalent-cations-information']) ||
      (this.state['ptm-required'] == 'unsure' && !this.state['ptm-required-information']) ||
      (this.state['chaperone'] == 'unsure' && !this.state['chaperone-information']) ||
      (this.state['terminal-scar'] == 'unsure' && !this.state['terminal-scar-information']) ||
      (this.state['protein-requirements'] == 'unsure' && !this.state['protein-requirements-information'])
     ) {
      this.setState({errorMessage: 'You answered yes on a question above. Please provide details so we can help you.'})
     } else {
       let metadata = [
              {"questionId": "molecule-cofactor", "answer": this.state['molecule-cofactor'], "information": this.state['molecule-cofactor-information']},
              {"questionId": "divalent-cations", "answer": this.state['divalent-cations'], "information": this.state['divalent-cations-information']},
              {"questionId": "ptm-required", "answer": this.state['ptm-required'], "information": this.state['ptm-required-information']},
              {"questionId": "chaperone", "answer": this.state['chaperone'], "information": this.state['chaperone-information']},
              {"questionId": "terminal-scar", "answer": this.state['terminal-scar'], "information": this.state['terminal-scar-information']},
              {"questionId": "protein-requirements", "answer": this.state['protein-requirements'], "information": this.state['protein-requirements-information']}
            ]

      this.props.saveAndContinue(3, [], metadata, false)
     }
  }

  renderStepTwo = () => {
    let { loading, total, url, results, errorMessage } = this.state;
    console.log(loading, total, url, results )

    return (
      <div key={url} className='ordersTable--main' style={{ paddingTop: '40px', width: '90%', margin: '0 auto'}}>
        <div className='menu-container' style={{ }}>
          {this.StageHeader()}
        </div>
        <Divider />
        {this.renderQuestionaire()}
        <div className='menu-container' style={{ justifyContent: 'flex-end'}}>
          <Menu compact style={{ border: 'none', boxShadow: 'none', marginTop: '20px'}}>
            <Button color='green'  size='medium'
              onClick={() => this.handleSubmit()}
            >
              Save & Continue <Icon name="arrow right" />
            </Button>
              {errorMessage ? <div><br/><small style={{color: 'red', fontSize: '14px'}}>{errorMessage}</small></div> : ''}
          </Menu>
        </div>
      </div>
    )
  }

  render() {
    let { url } = this.state;

    return (
        this.renderStepTwo()
    )
  }
}