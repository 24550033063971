import React, { useEffect, useState } from "react";
import {
  Button,
  Segment,
  Label,
  Grid,
  Loader,
  Header,
  Container,
  Divider,
  Icon,
} from "semantic-ui-react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import { Client, customLink } from "../../helpers/prismic";
import { getCurrentUser, refreshSession } from "../../helpers/auth";
import { createOrder } from "../../helpers";
import { getUserInfo } from "../../db/Users";

const StepImg = styled.img`
  margin-bottom: 1rem;
`;

const SpecTitle = styled.strong`
  margin-top: 1em;
  display: block;
`;

const SpecDescription = styled.div`
  font-size: 16px;
  margin-top: 1em;
`;

const SectionTitle = styled(({ ...other }) => <Header sub {...other} />)`
  &&& {
    padding-bottom: 40px;
    position: relative;
    text-align: center;
    &:after {
      content: "";
      display: block;
      width: 100px;
      height: 2px;
      background: #b4b4b4;
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px);
    }
  }
`;

const ProductPage = () => {
  const [doc, setDoc] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "product"),
        {
          fetchLinks: ["faq.question", "faq.answer"],
        }
      );
      if (response) {
        setDoc(response.results[0]);
      }
      setLoading(false);
    };

    fetchData();

    getCurrentUser((err, account) => {
      if (!err) {
        return getUserInfo(account["custom:accountId"])
          .then((account) => {
            return refreshSession(
              account.refreshToken,
              async (err, session) => {
                if (!err) {
                  setIdToken(session.getIdToken().getJwtToken());
                } else {
                  console.log("error ", err);
                }
              }
            );
          })
          .catch((err) => console.log("err ", err));
      }
      console.log("err ", err);
      return;
    });
  }, []);

  return (
    <>
      {doc && !loading ? (
        <>
          <Container>
            <Segment basic padded="very">
              <Header as="h1" textAlign="center">
                {doc.data.title[0].text}
              </Header>
            </Segment>
            <Grid stackable relaxed padded verticalAlign="middle">
              <Grid.Column computer={9}>
                <Grid stackable stretched textAlign="center">
                  {doc.data.order_steps.map((step, idx) => (
                    <Grid.Column computer={8} key={idx}>
                      <StepImg src={step.image.url} />
                      <div>
                        <Label circular size="large">
                          {idx + 1}
                        </Label>
                      </div>
                      <Header as="h4">{step.title}</Header>
                      <RichText render={step.description} />
                    </Grid.Column>
                  ))}
                </Grid>
              </Grid.Column>
              <Grid.Column computer={7}>
                <Segment basic padded tertiary>
                  <Header sub>Specifications</Header>
                  {doc.data.specifications.map((spec, idx) => (
                    <React.Fragment key={idx}>
                      {idx !== 0 && <Divider />}
                      <Header sub className="muted">
                        {spec.label}
                      </Header>
                      {spec.title && (
                        <SpecTitle>
                          <img src={spec.icon.url} alt="Icon" /> {spec.title}
                        </SpecTitle>
                      )}
                      <SpecDescription>
                        <RichText
                          render={spec.description}
                          serializeHyperlink={customLink}
                          Component="div"
                        />
                      </SpecDescription>
                    </React.Fragment>
                  ))}
                  <Button
                    fluid
                    color="blue"
                    size="large"
                    onClick={async () => {
                      let order = await createOrder(idToken);
                      if (order.order_id) {
                        window.location.href = "/order/" + order.order_id;
                      } else {
                        alert("Problem initiating order");
                      }
                    }}
                  >
                    Start Your Order <Icon name="arrow right" />
                  </Button>
                </Segment>
                <Segment basic textAlign="center">
                  <RichText
                    render={doc.data.fine_print}
                    serializeHyperlink={customLink}
                    Component="small"
                  />
                </Segment>
              </Grid.Column>
            </Grid>
          </Container>
          <Divider section />
          {doc.data.related_faq.length > 0 && (
            <Container>
              <Segment basic padded>
                <SectionTitle>Frequently Asked Questions</SectionTitle>
              </Segment>
              <Grid stackable relaxed padded stretched>
                {doc.data.related_faq.map((faq, idx) => (
                  <Grid.Column computer={8} key={idx}>
                    {faq.faq.data && (
                      <Segment basic padded>
                        <Header as="h4">{faq.faq.data.question[0].text}</Header>
                        <RichText render={faq.faq.data.answer} />
                      </Segment>
                    )}
                  </Grid.Column>
                ))}
              </Grid>
              <Segment basic padded textAlign="center">
                <Button
                  size="large"
                  primary
                  basic
                  className="link"
                  as={Link}
                  to="/faq"
                >
                  View all FAQs
                  <Icon name="angle right" />
                </Button>
              </Segment>
            </Container>
          )}
        </>
      ) : (
        <Loader active />
      )}
    </>
  );
};

export default ProductPage;
