import CONFIG from '../config'
import AWS from 'aws-sdk'

AWS.config.region = CONFIG.Region;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: CONFIG.CognitoIdentityPool,
  RoleArn: CONFIG.RoleArn,
  AccountId: CONFIG.AccountId, // your AWS account ID
  });

let docClient = new AWS.DynamoDB.DocumentClient({
  region: CONFIG.Region
});

export function getUserInfo(accountId) {
  const params = {
    TableName: CONFIG.DDBUserTable,
    IndexName: 'userByAccountId', // optional (if querying an index)
    KeyConditionExpression: "#accountId = :accountId",
    ExpressionAttributeNames: {
      "#accountId": "accountId"
    },
    ExpressionAttributeValues: {
      ":accountId": accountId
    }
  };
  
  let promise = new Promise((resolve, reject) => {
    docClient.query(params).promise().then((data) => {
      resolve(data.Items[0]);
    }).catch((err) => {
      reject(err);
    });
  });

  return promise;
}

export function saveApplicationUser(accountId, email, firstName, lastName, phone, companyName, callback) {
  let params = {
      TableName: CONFIG.DDBUserTable,
      Item:{
          "accountId": accountId,
          "userId": email,
          "status": 'active',
          "email": email,
          "phone": phone,
          "firstName": firstName,
          "lastName": lastName,
          "companyName": companyName,
          "role": 'owner',
          "created": new Date().toUTCString(),
      }
  };
  
  return docClient.put(params, callback);
}

export function saveTokens(accountId, accessToken, idToken, refreshToken, callback) {
  let params = {
      TableName: CONFIG.DDBUserTable,
      Item:{
          "accountId": accountId,
          "accessToken": accessToken, 
          "idToken": idToken,
          "refreshToken": refreshToken,
      }
  };
  
  return docClient.put(params, callback);
}