import React from 'react'
import {Form, Input, Select} from 'antd'
const Option = Select.Option;
const { TextArea } = Input;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

export const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends React.Component {
  state = {
    editing: false,
    origin: '',
    sequence: '',
    sequenceErrorMessage: '',
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing && this.input) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    let { origin } = this.state;

    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      if (origin) {
        let values = {'origin': origin}
        handleSave({ ...record, ...values } );
      } else {
        handleSave({ ...record, ...values });
      }
    });
  };

  saveOrigins = (value) => {
    this.setState({ origin: value}, () => this.save())
  }

  saveSequence = (value) => {
    let _value = value.currentTarget.value;
    const validator = RegExp(/^[GALMFWKQESPVICYHRNDT\*]+$/g);

    if (!validator.test(_value)) {
      // console.log('hit')
      this.setState({ 
        sequenceErrorMessage: 'Sequence contains non-amino acid characters',
        // sequence: _value this.setState({ sequence: e.currentTarget.value})
      })
    } else {
      this.setState({ 
        sequence: _value,
        sequenceErrorMessage: '',
      })
    }
  }

  setStyle = (title) => {
    let style;

    switch (title) {
      case 'Sequence':
        style = { paddingRight: 24, minHeight: 32, wordBreak: 'break-word' }
        break;
    
      default:
        style = { paddingRight: 24, minHeight: 32}
        break;
    }

    return style;
  }

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title, index } = this.props;
    let { editing, sequenceErrorMessage, sequence } = this.state;

    // sequence = sequence !== '' ? sequence : this.props.record.sequence;

    // console.log(children, record)
    let style = this.setStyle(title);

    let inputMap = () => {
      if (title === 'Sequence') {
        return (
            form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: this.props.record.sequence,
          })(
            <TextArea autosize={{ minRows: 3 }}  ref={node => (this.input = node)} onChange={this.saveSequence} onPressEnter={this.save} onBlur={this.save} />
          )
        )
      }
      
      if (title === 'Origin') {
        return (
            form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(<Select ref={node => (this.input = node)} key="origin" style={{ width: 120 }} onPressEnter={this.saveOrigins} onBlur={this.saveOrigins} onChange={this.saveOrigins}>
              <Option key="synthetic" value="synthetic">synthetic/unknown</Option>
              <Option key="eukaryota" value="eukaryota">eukaryota</Option>
              <Option key="prokaryota" value="prokaryota">prokaryota</Option>
              <Option key="archea" value="archea">archea</Option>
              <Option key="viral" value="viral">viral</Option>
              <Option key="mixed" value="mixed">mixed</Option>
            </Select>
          )
        )
      }

      return (
          form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)
      )
      
    }

    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {inputMap()}
        {sequenceErrorMessage.length > 0 ? <small style={{color: 'red'}}>{sequenceErrorMessage}</small> : ''}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={style}
        onClick={this.toggleEdit}
        key={index}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;

    let style = this.setStyle(title);

    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          <div
            key={index}
            className="editable-cell-value-wrap"
            style={style}
          >
            {children}
          </div>
        )}
      </td>
    );
  }
}