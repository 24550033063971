export const formatSequenceFromAPI = (sequences) => {
    if (sequences.length < 0) return sequences;
    
    return sequences.map((d,i) => {
        return {
            id: d.id,
            sequence: d.sequence.replace(/\s+/g, '') || '',
            final_sequence: d.final_sequence || '',
            name: d.customer_sequence_name || d.name,
            origin: d.attribute_details || d.origin,
            feasability: d.result_string || '',
            flags: d.flags_comments || '',
        }
    })
}