import React, { Component } from "react";
import {
  Segment,
  Button,
  Form,
  Grid,
  Header,
  Message,
} from "semantic-ui-react";

import { Redirect, Link } from "react-router-dom";
import {
  MSG_PASSWORD_PATTERN,
  checkEmailPattern,
  checkPasswordPattern,
  checkNamePattern,
  loginCallbackFactory,
  handleResendVerificationCode,
  handleSubmitVerificationCode,
  handleSignUp,
  checkSignUpError,
} from "../../helpers/auth";
import { saveApplicationUser } from "../../db/Users";
import Verification from "../Verification";

import { CONFIG } from "../../config";
import * as CONSTANTS from "../../constants";

import { v4 as uuidv4 } from "uuid";

const STAGE_REGISTER = "STAGE_REGISTER";
const STAGE_VERIFICATION = "STAGE_VERIFICATION";
const STAGE_REDIRECT = "STAGE_REDIRECT";

const COUNT_DOWN_RESEND = 30;
const COUNT_DOWN_REDIRECT = 5;

const VERIFICATION_BY_CODE = true;

export default class Register extends Component {
  constructor() {
    super();

    // this.username;
    // this.password;
    this.accountId = "";

    this.saveUserCallback = this.saveUserCallback.bind(this);
    this.saveApplicationUser = saveApplicationUser.bind(this);
    this.signUpCallback = this.signUpCallback.bind(this);
    this.verificationCallback = this.verificationCallback.bind(this);

    this.state = {
      name: "",
      companyName: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordMatch: "",
      email: "",
      phone: "",
      code: "",
      invalidCodeMessage: "",
      errorMessage: "",
      accountId: "",
      stage: STAGE_REGISTER,
      countDown: 0,
      loading: false,
    };
  }

  seconds = setInterval(() => {
    if (this.state.countDown > 0) {
      this.setState({
        countDown: this.state.countDown - 1,
      });
    }
  }, 1000);

  componentWillUnmount() {
    clearInterval(this.seconds);
  }

  componentDidMount() {}

  /// //////////////////// callback for auth lib /////////////////////
  // Callback structure here is not consistent with Login and Forget component,
  // due to the inconsistent design of aws-cognitio api: "onSuccess" vs "error"
  signUpCallback = (error, data) => {
    if (error) {
      this.setState({
        errorMessage: checkSignUpError(error),
      });
    } else {
      if (!VERIFICATION_BY_CODE) {
        saveApplicationUser(
          this.state.accountId,
          this.state.email,
          this.state.firstName,
          this.state.lastName,
          this.state.phone,
          this.state.companyName,
          () => {
            this.setState({
              errorMessage: "",
              // accountId: this.accountId,
              stage: STAGE_REDIRECT,
              countDown: COUNT_DOWN_REDIRECT,
            });
          }
        );
      } else {
        this.setState({
          errorMessage: "",
          accountId: this.accountId,
          stage: STAGE_VERIFICATION,
          countDown: COUNT_DOWN_REDIRECT,
        });
      }
    }
  };

  callbacks = loginCallbackFactory(
    {
      onSuccess() {
        this.setState({
          stage: STAGE_REDIRECT,
          errorMessage: "",
          logging: false,
        });
      },
      onFailure(error) {
        this.setState({
          errorMessage: error,
          logging: false,
        });
      },
      mfaRequired() {
        this.setState({
          stage: STAGE_VERIFICATION,
          countDown: COUNT_DOWN_RESEND,
          errorMessage: "",
          logging: false,
        });
      },
    },
    this
  );

  verificationCallback = (error, data) => {
    if (error) {
      this.setState({
        errorMessage: "Invalid verification code.",
      });
    } else {
      console.log(this.state);
      saveApplicationUser(
        this.state.accountId,
        this.state.email,
        this.state.firstName,
        this.state.lastName,
        this.state.phone,
        this.state.companyName,
        this.saveUserCallback
      );
    }
  };

  saveUserCallback = (error, data) => {
    if (error) {
      this.setState({
        errorMessage: "Error saving new user.",
      });
    } else {
      this.setState({
        errorMessage: "",
        stage: STAGE_REDIRECT,
        countDown: COUNT_DOWN_REDIRECT,
      });
    }
  };

  resendCodeCallback = (error, result) => {
    if (error) {
      this.setState({
        errorMessage: "Resend code fail. Please retry.",
      });
    } else {
      this.setState({
        errorMessage: "",
        countDown: COUNT_DOWN_RESEND,
      });
    }
  };

  handleSubmit = () => {
    this.username = this.state.email;
    this.password = this.state.password;
    // console.log('state ', this.state)

    handleSignUp(
      this.state.email,
      this.state.password,
      `${this.state.firstName} ${this.state.lastName}`,
      this.state.accountId,
      this.state.companyName,
      this.signUpCallback
    );
  };

  handleSubmitVerification = () => {
    if (!this.state.code) {
      this.setState({
        errorMessage: "Code cannot be empty.",
      });
    } else {
      handleSubmitVerificationCode(
        this.state.email,
        this.state.code,
        this.verificationCallback
      );
    }
  };

  handleResendVerification = () => {
    this.setState({
      countDown: COUNT_DOWN_RESEND,
    });
    handleResendVerificationCode(this.state.email, this.resendCodeCallback);
  };

  checkPasswordMatch = () => {
    return this.state.password === this.state.passwordMatch;
  };

  renderErrorMessage = (message) => {
    return (
      <Message negative style={{ textAlign: "left" }}>
        Error: {message}
      </Message>
    );
  };

  checkFields = (e) => {
    const { firstName, lastName, email, password, passwordMatch } = this.state;
    if (!firstName || !lastName || !password || !passwordMatch) {
      e.stopPropagation();
      this.setState({
        loading: false,
        errorMessage: "Please fill all fields in the form below.",
      });
      return;
    } else if (
      !checkNamePattern(firstName) ||
      !checkNamePattern(lastName) ||
      !checkEmailPattern(email) ||
      !checkPasswordPattern(password) ||
      !this.checkPasswordMatch()
    ) {
      e.stopPropagation();
      this.setState({
        loading: false,
        errorMessage: "Invalid input.",
      });
      return;
    }

    this.accountId = uuidv4();

    this.setState(
      {
        accountId: this.accountId,
        loading: true,
      },
      () => this.handleSubmit()
    );
  };

  renderRegister = () => {
    const { errorMessage, name, email, password, passwordMatch } = this.state;

    return (
      <div
        style={{
          background: "#f9f9f9",
          minHeight: "100vh",
        }}
      >
        <Grid
          textAlign="center"
          style={{
            paddingTop: 120,
          }}
          //   verticalAlign='middle'
        >
          <Grid.Column
            style={{
              width: 600,
              alignItems: "center",
            }}
          >
            {errorMessage && this.renderErrorMessage(errorMessage)}
            <Form size="large">
              <Segment padded="very">
                {/* <img style={{ width: '120px'}} src={logo} /> */}
                <Header as="h2" textAlign="center">
                  Register
                </Header>
                <br />
                <Form.Input
                  fluid
                  icon="building"
                  iconPosition="left"
                  placeholder="Company Name"
                  onChange={(event) =>
                    this.setState({
                      companyName: event.target.value.trim(),
                      errorMessage: "",
                    })
                  }
                />
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="First Name"
                    onChange={(event) =>
                      this.setState({
                        firstName: event.target.value.trim(),
                        errorMessage: "",
                      })
                    }
                  />
                  {name &&
                    !checkNamePattern(name) &&
                    this.renderErrorMessage("Invalid name")}

                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="Last Name"
                    onChange={(event) =>
                      this.setState({
                        lastName: event.target.value.trim(),
                        errorMessage: "",
                      })
                    }
                  />
                  {name &&
                    !checkNamePattern(name) &&
                    this.renderErrorMessage("Invalid name")}
                </Form.Group>
                <Form.Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder="Email"
                  onChange={(event) =>
                    this.setState({
                      email: event.target.value.trim(),
                      errorMessage: "",
                    })
                  }
                />
                {email &&
                  !checkEmailPattern(email) &&
                  this.renderErrorMessage("Invalid email format")}

                <Form.Input
                  fluid
                  icon="phone"
                  iconPosition="left"
                  placeholder="Phone"
                  onChange={(event) =>
                    this.setState({
                      phone: event.target.value.trim().replace(/\D/g, ""),
                      errorMessage: "",
                    })
                  }
                />

                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    onChange={(event) =>
                      this.setState({
                        password: event.target.value.trim(),
                        errorMessage: "",
                      })
                    }
                  />

                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password Confirm"
                    type="password"
                    onChange={(event) =>
                      this.setState({
                        passwordMatch: event.target.value.trim(),
                        errorMessage: "",
                      })
                    }
                  />
                </Form.Group>
                {password &&
                  !checkPasswordPattern(password) &&
                  this.renderErrorMessage(MSG_PASSWORD_PATTERN)}
                {password &&
                  passwordMatch &&
                  !this.checkPasswordMatch() &&
                  this.renderErrorMessage("Password does not match")}
                <br />
                <Button
                  loading={this.state.loading}
                  color="blue"
                  fluid
                  size="large"
                  onClick={this.checkFields}
                >
                  Sign Up
                </Button>
                {/* <p>I accept EasyBot Lead's <a href="">term of service</a> and <a href="">privacy policy</a>.</p> */}
                <br />
                <p>
                  {" "}
                  Already have an account ? <Link to="/login"> Log In </Link>
                </p>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  renderVerification = () => {
    return (
      <Verification
        errorMessage={this.state.errorMessage}
        countDown={this.state.countDown}
        onChange={(event) =>
          this.setState({ code: event.target.value.trim(), errorMessage: "" })
        }
        onValidate={this.handleSubmitVerification}
        onResendCode={this.handleResendVerification}
      />
    );
  };

  renderRedirect = () => {
    if (this.state.countDown > 0) {
      return (
        <Grid
          textAlign="center"
          style={{ paddingTop: 120 }}
          verticalAlign="middle"
        >
          <Message success style={{ textAlign: "left" }}>
            {VERIFICATION_BY_CODE && (
              <p> Your registration has been successful. </p>
            )}
            {!VERIFICATION_BY_CODE && (
              <p>
                {" "}
                Please check your email and click the link to verify your email.{" "}
              </p>
            )}
            <p>
              {" "}
              Redirecting to login page in {this.state.countDown} seconds.{" "}
            </p>
          </Message>
        </Grid>
      );
    } else {
      clearInterval(this.seconds);
      return <Redirect to="/login" />;
    }
  };

  render = () => {
    return (
      <div>
        {this.state.stage === STAGE_REGISTER && this.renderRegister()}
        {this.state.stage === STAGE_VERIFICATION && this.renderVerification()}
        {this.state.stage === STAGE_REDIRECT && this.renderRedirect()}
      </div>
    );
  };
}
