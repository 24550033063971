import React from 'react'
import { Dropdown, Menu, Divider, Button, Step, Icon } from 'semantic-ui-react'
import { RIEToggle, RIEInput, RIETextArea, RIENumber, RIETags, RIESelect } from 'riek'
import _ from 'lodash'
import { Table, Button as ButtonAnt, Popconfirm, Row, Col, Icon as IconAnt, Upload } from "antd"
import { ExcelRenderer } from "react-excel-renderer"
import Moment from 'react-moment';
import { removeSequences } from '../../helpers/index';
import { EditableFormRow, EditableCell } from "../../utils/editable";

import "react-table/react-table.css";

import './OrderStepOne.css';

const dummySequences = [
  {
    id: 1,
    name: 'B12',
    origin: 'synthetic',
    sequence: 'BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH'
  },
  {
    id: 2,
    name: 'B22',
    origin: 'eukaryota',
    sequence: 'BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH'
  },
  {
    id: 3,
    name: 'B24',
    origin: 'prokaryota',
    sequence: 'BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH'
  },
  {
    id: 4,
    name: 'B94',
    origin: 'archea',
    sequence: 'BHCBUPSPKJHXLZVLVCTYVTVUUZFQIZNZGRFEMPFHAOACITJCSEXGBJYLKMQH'
  },
]

export default class OrderStepOne extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: this.props.account,
      sequences: this.props.sequences || [],
      cols: [],
      rows: this.props.sequences || [],
      errorMessage: null,
      isOrderSubmitted: this.props.isOrderSubmitted,
      canSubmit: false,
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          editable: true
        },
        {
          title: "Origin",
          dataIndex: "origin",
          editable: true
        },
        {
          title: "Sequence",
          dataIndex: "sequence",
          editable: true,
          onPressEnter: () => {
            console.log('hit')
          }
        },
        {
          title: "Action",
          dataIndex: "action",
          render: (text, record) =>
            this.state.rows.length >= 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record.id)}
              >
                <IconAnt
                  type="delete"
                  theme="filled"
                  style={{ color: "red", fontSize: "20px" }}
                />
              </Popconfirm>
            ) : null
        }
      ]
    }

    this.page = 0;
  }

  componentDidMount = () => {
    let { rows } = this.state;

    if (rows && rows.length > 0) {
      this.setState({canSubmit: true})
    }
  }
  
  onSelectChange = selectedRowKeys => {
    let {rows} = this.state;
    
    let selectedRows = [];
    selectedRowKeys.map(d => selectedRows.push(rows[d].id))
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    console.log('selectedRows: ', selectedRows);
    if (selectedRows.length === 1) {
      selectedRows = selectedRows[0]
    }

    this.setState({ 
      selectedRowKeys,
      selectedRows
    });
  };

  handleSave = row => {
    // console.log('handleSave row ', row)
    let _row = Object.assign(row, {sequence: row.sequence.replace(/\s+/g, '')})
    const newData = [...this.state.rows];
    // console.log('handleSave newData 1 ', newData)
    const index = newData.findIndex(item => _row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ..._row
    });
    // console.log('handleSave newData 2 ', newData)
    this.setState({ rows: newData });
  };

  checkFile(file) {
    let errorMessage = "";
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === "application/vnd.ms-excel" ||
      file[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      errorMessage = "You can only upload Excel file!";
    }
    console.log("file", file[0].type);
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorMessage = "File must be smaller than 2MB!";
    }
    console.log("errorMessage", errorMessage);
    return errorMessage;
  }

  fileHandler = fileList => {
    // console.log("fileList", fileList);
    let fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: "No file uploaded!"
      });
      return false;
    }
    // console.log("fileObj.type:", fileObj.type);
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      this.setState({
        errorMessage: "Unknown file format. Only Excel files are uploaded!"
      });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let newRows = [...this.state.rows];
        // console.log('newRows ', newRows)
        
        resp.rows.slice(1).map((row, index) => {
          if (row && row !== "undefined") {
            newRows.push({
              id: index,
              sequence: row[2].replace(/\s+/g, ''),
              name: row[0],
              origin: row[1].toString().toLowerCase(),
            });
          }
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: "No data found in file!"
          });
          return false;
        } else {
          // console.log('newRows ', newRows)
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
            canSubmit: true,
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {
    let rows = JSON.stringify(this.state.rows);
    this.props.saveAndContinue(2, rows, null, false);
  };

  handleDelete = async (ids) => {
    console.log('ids ', ids)
    await removeSequences(this.props.idToken, this.props.order_id, ids)
    const rows = [...this.state.rows];
    this.setState({ 
      rows: rows.filter((row) => Array.isArray(ids) ? !ids.includes(row.id) : row.id !== ids ),
      selectedRowKeys: [],
    });
    console.log('rows ', this.state.rows)
  };
  handleAdd = () => {
    const { rows } = this.state;
    const newData = {
      id: rows.length + 1,
      name: `A${rows.length + 1}`,
      origin: "synthetic",
      sequence: "MRNSLFTTLFFGLAALVMVLNAQYTSELEEDEKRGGARAMLHKRGGARAFSADVGDDYKRGGARAFYDEKRGGARAFLTEMKRGGARVFQGFEDEKRGGARAFMMDKRGGGRAFGDMMKRGGARAFVENSKRDEDWVIRPFEDDRLGVF"
    };
    this.setState({
      rows: [newData, ...rows],
      canSubmit: true,
    });
  };

  StageHeader = () => (
    <Step.Group fluid stackable='tablet'>
      <Step active>
        <Icon name='dna' />
        <Step.Content>
          <Step.Title>Sequences</Step.Title>
          <Step.Description>Add your sequences</Step.Description>
        </Step.Content>
      </Step>
      <Step disabled={this.props.isDisabledTwo} link onClick={() => this.props.toggleStep(2)}>
        <Icon name='tasks' />
        <Step.Content>
          <Step.Title>Additional Info</Step.Title>
          <Step.Description>Enter any special requirements</Step.Description>
        </Step.Content>
      </Step>
      <Step disabled={this.props.isDisabledThree} link onClick={() => this.props.toggleStep(3)}>
        <Icon name='cart' />
        <Step.Content>
          <Step.Title>Review Order</Step.Title>
          <Step.Description>Verify order details</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  )

  renderStepOne = () => {
    let  {selectedRowKeys} = this.state;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          key: col.key,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div key={this.props.sequences} className='ordersTable--main' style={{ paddingTop: '40px', width: '90%', margin: '0 auto'}}>
        <div className='menu-container' style={{ }}>
          {this.StageHeader()}
          <Menu compact style={{ border: 'none', boxShadow: 'none', justifyContent: 'flex-end'}}>
            
          </Menu>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" onClick={() => this.handleDelete(this.state.selectedRows)} disabled={!hasSelected}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={this.state.rows}
          columns={columns}
          rowSelection={rowSelection}
        />
        <div className='menu-container' style={{ justifyContent: 'flex-end'}}>
            {this.state.isOrderSubmitted ? 
              <span>Order submitted on <Moment format="MM/DD/YYYY">{this.props.order.order_submitted}</Moment></span>
            :
              <Menu compact style={{ border: 'none', boxShadow: 'none', marginTop: '20px'}}>
                <div>
                  <Button
                    onClick={this.handleAdd}
                    size="medium"
                    basic
                  >
                    Add a Row
                  </Button>{" "}
                  {/* <Button
                    onClick={this.handleSubmit}
                    size="meduim"
                    type="primary"
                    style={{ marginBottom: 16, marginLeft: 10 }}
                  >
                    Submit Data
                  </Button> */}
                  <Button basic size='medium'
                    onClick={() => window.location.href = 'https://tierra-app-assets.s3-us-west-2.amazonaws.com/tierra-template.xlsx'}
                  >
                    Download Template
                  </Button>
                  <Upload
                    name="file"
                    beforeUpload={this.fileHandler}
                    onRemove={() => this.setState({ rows: [] })}
                    multiple={false}
                    showUploadList={false}
                    style={{height: '100%'}}
                  >
                    <Button size="medium" color='blue'>
                      <Icon name="upload" /> Upload Sequence Excel File
                    </Button>
                  </Upload>
                </div>
                <div></div>
                {/* <div style={{borderLeft: 'solid grey', padding: '0px 5px'}}> */}
                <div>
                  <Button 
                    color='green'
                    size='medium'
                    onClick={() => this.handleSubmit()}
                    disabled={!this.state.canSubmit}
                  >
                    Save & Continue <Icon name="arrow right" />
                  </Button>
                </div>
              </Menu>
            }
        </div>
      </div>
    )
  }

  render() {
    return (
        this.renderStepOne()
    )
  }
}